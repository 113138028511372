































import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import InsProductItem from '@/components/business/mobile/product/InsProductItem.vue';
import { Promotion } from '@/model/promotionResult';
import Catalogs from '@/model/Catalogs';
@Component({ components: { InsProductItem } })
export default class EdMonterssori extends Vue {
  private promotion: Promotion = new Promotion();
  @Prop({ default: 2 }) column!: number;
  @Prop({ default: 100 }) w!: number;
  @Prop({ default: true }) border!: boolean;
  @Prop({ default: true }) top!: boolean;
  @Prop({ default: true }) button!: boolean;
  @Prop({ default: true }) private code!: boolean;
  private catalogs: Catalogs = new Catalogs();
  // private Desc: string='';
  // private DescList: string[] = [];
  created () {
    this.$Api.promotion.getPromotion('Home', 2).then(result => {
      this.promotion = result.Promotion;
    });
    //   this.$Api.product.getCatalogs(29).then((result) => {
    //     this.catalogs = result.Catalogs;
    //   });
  }
  // @Watch('Desc')
  // onDescChange () {
  //   this.DescList = this.Desc.split(';');
  // }
}
