
















import inButton from '@/components/base/mobile/InsButton.vue';
import inPrices from '@/components/base/mobile/InsPrices.vue';
import YouWouldLike from '@/model/youWouldLike';
import { Vue, Prop, Component } from 'vue-property-decorator';
@Component({ components: { inButton, inPrices } })
export default class InsProductItem extends Vue {
    private Enter:boolean = false;
    @Prop() private item!:YouWouldLike;
    @Prop() private styla!:string;
    @Prop({ default: 0 }) private index!:number;
    @Prop({ default: true }) private button!:boolean;
    @Prop({ default: false }) private borderRadius!:boolean;
    @Prop({ default: true }) private code!:boolean;
    buttonSubmit (item) {
      this.$Api.product.GetProduct(item.Sku).then((result) => {
        if (result.PanelDetail.AttrList[0].length !== 0) { this.$router.push('/product/detail/' + item.Sku); return Promise.reject(new Error()); } else return item.Sku;
      }).then((Sku) => {
        return this.$Api.shoppingCart.addItem(Sku, 1);
      }).then((AI) => {
        this.$message(AI.Message.Message);
        this.$Api.shoppingCart.getShoppingCart();
      });
    }
    click (e) {
      let target = e.target as HTMLElement;
      if (target.nodeName === 'IMG') { this.$router.push('/product/detail/' + target.dataset.key); };
    }
}
